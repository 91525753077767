import { useLocation } from "@remix-run/react";
import React from "react";
import { pageview } from "~/util/gtags.client";

export function usePageView(gaTrackingId: string) {
  const location = useLocation();

  React.useEffect(() => {
    if (gaTrackingId?.length) {
      pageview(location.pathname, gaTrackingId);
    }
  }, [location, gaTrackingId]);
}
