import { Link, isRouteErrorResponse, useRouteError } from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import type { CSSProperties } from "react";

export function ErrorBoundary() {
  const error = useRouteError();

  let errorCode = "";
  let stackTrace = "";

  if (isRouteErrorResponse(error)) {
    errorCode = error.status.toString();
  } else if (error instanceof Error) {
    stackTrace = error.stack || "";
  }

  captureRemixErrorBoundaryError(error);

  return (
    <>
      <style>
        {`
          body, html {
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
          }
          #toggleModal {
            display: none;
          }
          .modal {
            display: none;
            position: fixed;
            z-index: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: rgba(0,0,0,0.4);
          }
          #toggleModal:checked ~ .modal {
            display: block;
          }
        `}
      </style>
      <div
        style={{
          ...styles.container,
          fontFamily: styles.fontFamily as CSSProperties["fontFamily"],
        }}
      >
        <div style={styles.content}>
          <div style={styles.imageContainer}>
            <img src="/404.png" alt="404 Error" style={styles.image} />
          </div>
          <h1 style={styles.heading}>Oops! Something went wrong.</h1>
          <p style={styles.paragraph}>
            We're on it! We'll get things sorted as soon as possible.
          </p>

          <div style={styles.buttonContainer}>
            <Link to=".." style={{ ...styles.button, ...styles.primaryButton }}>
              Go Back
            </Link>
            <Link
              to="/"
              style={{ ...styles.button, ...styles.secondaryButton }}
            >
              Go to Home
            </Link>
            <label
              htmlFor="toggleModal"
              style={{ ...styles.button, ...styles.tertiaryButton }}
            >
              View Stack Trace
            </label>
          </div>
        </div>
      </div>
      <input type="checkbox" id="toggleModal" />
      <div className="modal">
        <div style={styles.modalContent}>
          <label htmlFor="toggleModal" style={styles.closeButton}>
            &times;
          </label>
          <h2
            style={{
              color: "black",
              fontFamily: styles.fontFamily as CSSProperties["fontFamily"],
            }}
          >
            Stack Trace
          </h2>
          {errorCode && (
            <pre style={styles.stackTrace}>{`ERROR CODE: ${errorCode}`}</pre>
          )}
          <pre style={styles.stackTrace}>{stackTrace}</pre>
        </div>
      </div>
    </>
  );
}

const styles = {
  fontFamily:
    "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
  container: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#000",
    padding: "3rem 1rem",
    margin: 0,
    boxSizing: "border-box",
  },
  content: {
    maxWidth: "32rem",
    textAlign: "center",
    margin: "auto",
  },
  imageContainer: {
    position: "relative",
    height: "16rem",
    width: "16rem",
    margin: "0 auto",
  },
  image: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  heading: {
    marginTop: "2rem",
    fontSize: "2.25rem",
    color: "white",
  },
  paragraph: {
    marginTop: "1rem",
    fontSize: "1.125rem",
    color: "#B3B3C3",
  },
  buttonContainer: {
    marginTop: "2rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  button: {
    display: "inline-flex",
    height: "2.5rem",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0.375rem",
    padding: "0 1.5rem",
    fontSize: "0.875rem",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    transition: "background-color 0.2s",
    border: "none",
    cursor: "pointer",
    textDecoration: "none",
  },
  primaryButton: {
    backgroundColor: "#9747FF",
    color: "white",
  },
  secondaryButton: {
    backgroundColor: "transparent",
    border: "1px solid #B3B3C3",
    color: "#B3B3C3",
  },
  tertiaryButton: {
    backgroundColor: "#fff",
    color: "black",
  },
  modalContent: {
    backgroundColor: "#fefefe",
    margin: "15% auto",
    padding: "20px",
    border: "1px solid #888",
    width: "80%",
    maxWidth: "600px",
    borderRadius: "20px",
  },
  closeButton: {
    color: "#aaa",
    float: "right",
    fontSize: "28px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  stackTrace: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    color: "black",
    textAlign: "left",
    backgroundColor: "#f0f0f0",
    padding: "1rem",
    borderRadius: "0.375rem",
    maxHeight: "400px",
    overflow: "auto",
  },
} as Record<string, CSSProperties>;
