import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { cssBundleHref } from "@remix-run/css-bundle";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";
import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { Toaster } from "sonner";
import * as toast from "~/components/toast";
import { WagmiProvider, rainbowKitConfig } from "~/config";
import "~/fixes/sonner/styles.css";
import { useCurrentUser } from "~/hooks/use-current-user";
import stylesheet from "~/index.css?url";
import { getCurrentUserFromSession } from "~/util/sessions/login";
import { ErrorBoundary } from "./components/error-boundary";
import { usePageView } from "./hooks/use-google-analytics";
import { queryClient } from "./util/react-query";
import type { ToastMessage } from "./util/sessions/flash";
import { getFlashSession } from "./util/sessions/flash";

export const links: LinksFunction = () => [
  ...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : []),
  { rel: "stylesheet", href: stylesheet },
];

export async function loader({ request }: LoaderFunctionArgs) {
  const { flash, headers: flashHeaders } = await getFlashSession(request);
  const currentUser = await getCurrentUserFromSession(request);
  const ENV = {
    NODE_ENV: process.env.NODE_ENV,
    GA_TRACKING_ID: process.env.GA_TRACKING_ID,
    EDGE_URL: process.env.EDGE_URL,
  };
  return json(
    {
      flash,
      ENV,
      currentUser,
    },
    {
      headers: flashHeaders,
    },
  );
}

function App() {
  const loaderData = useLoaderData<typeof loader>();
  const { flash } = useLoaderData<typeof loader>();
  useFlash(flash?.toast);

  const currentUser = loaderData.currentUser;
  useCurrentUser(currentUser);

  const gaTrackingId = loaderData.ENV.GA_TRACKING_ID as string;
  usePageView(gaTrackingId);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/soulbound-icon.png" type="image/png" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <Meta />
        {/* Google Tag Manager */}
        {gaTrackingId && (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
            />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${gaTrackingId}', {
                page_path: window.location.pathname,
              });
            `,
              }}
            />
          </>
        )}
        <Links />
      </head>
      <body className="w-full h-full min-h-screen bg-cover bg-no-repeat bg-[linear-gradient(122deg,#F0EAFF_37.36%,#EAD5E5_91.22%)]">
        <WagmiProvider config={rainbowKitConfig}>
          <QueryClientProvider client={queryClient}>
            <RainbowKitProvider>
              <Outlet />
            </RainbowKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
        <ScrollRestoration />
        <Scripts />
        <Toaster />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(loaderData.ENV)}`,
          }}
        />
      </body>
    </html>
  );
}

export default withSentry(App);

function useFlash(message?: ToastMessage) {
  React.useEffect(() => {
    if (!message) return;
    const tid = setTimeout(() => {
      const toastFn = message.variant === "success" ? toast.ok : toast.fail;
      toastFn({
        text: message.description as string,
        id: message.variant,
      });
    }, 200);
    return () => clearTimeout(tid);
  }, [message]);
}

export { ErrorBoundary };
